import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/conference/expert-pods"

    return (
        <Layout pageTitle="Expert Pods - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/conference/expert-pods/expert-pods-index">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                <h1 className="h1">
                Exhibiting Done Differently                   
                 </h1>
                
    
                </Col>
            </Row>
            <ImageDownload
                root={root}
                name="Expert Pod Promo"
                filename="expert-pod-promo"
                text="Click to see a selection of promo graphics for your use. You can use and adapt the marketing copy below for Expert Pods & Women&apos;s Business Conference"
                squareHref="https://www.canva.com/design/DAFnMZ6ErGA/rOemh6BOdVNEa1SxzNdcKw/view?utm_content=DAFnMZ6ErGA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFnMTB8qTM/qLpWmVKYtxNMGVPPrOr-Vg/view?utm_content=DAFnMTB8qTM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1H8r8KhTSL-vtNaY0j8ka4W88880IcQh7Bhd9rfFH9Kc/edit?usp=sharing"
            />


        </Layout>
    )
}

export default IndexPage
